export default {
    menuItems: [
      {
        name: 'ホーム',
        url: '/'
      },
      {
        name: '会社概要',
        url: '/company-profile'
      },
      {
        name: '事業内容',
        url: '/business-content'
      },
      {
        name: '商品購入',
        url: '/product-purchase'
      },
      {
        name: 'お問い合わせフォーム',
        url: '/contact'
      },
    ],
    privacyPolicy: {
      name: '個人情報の取り扱い',
      url: '/privacy-policy'
    },
  }