<template>
  <v-app>
    <AppHeader></AppHeader>
    <!-- vuetifyではトップページのコンポーネントは必ずv-contextで囲う必要がある
         →v-contestは非推奨? v-mainの方が良さそう -->
    <v-main class="main">
      <!-- vue routerによる画面遷移したものを表示する -->
      <router-view></router-view>
    </v-main>
    <AppFooter></AppFooter>
  </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader'
import AppFooter from '@/components/AppFooter.vue'


export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  }
}
</script>

<style scoped>
.main{
  background-color: rgb(232, 251, 232);
  overflow: hidden;
}

</style>