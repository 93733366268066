<template>
    <v-footer
      light
      color=#2e8b57 
      class="white--text"
    >
    <div id="responsive">
      <div class="etc-info" @click="PageTransition(privacyPolicy.url)">
              {{privacyPolicy.name}}
      </div>
      <div class="copyright">
          Copyright 2023 IKEDA CRAFTS CORPORATION.
      </div>
    </div>
    </v-footer>
</template>
<script>
  import constants from '@/common/consts'
  export default{
    data(){
        return{
            privacyPolicy: constants.privacyPolicy,
        }
    },
    methods:{
        PageTransition(url){
        this.$router.push(url)
      },
    }
  }
</script>

<style scoped>
.copyright{
    margin-left: auto;
}
.etc-info{
    cursor: pointer;
}
.etc-info:hover{
	font-weight: bold;
}
</style>