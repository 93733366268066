import Vue from 'vue'
import Router from 'vue-router'

/* vue routerプラグインを使用するためにvueプロジェクトに登録する */
Vue.use(Router)

/* 遅延ローディング */
const HomeOriginPage = () => import (/* webpackChunkName: "HomeOriginPage" */ '@/components/page/HomeOriginPage.vue');
const CompanyProfilePage = () => import (/* webpackChunkName: "CompanyProfilePage" */ '@/components/page/CompanyProfilePage.vue');
const BusinessContentPage = () => import (/* webpackChunkName: "BusinessContentPage" */ '@/components/page/BusinessContentPage.vue');
const ProductPurchasePage = () => import (/* webpackChunkName: "ProductPurchasePage" */ '@/components/page/ProductPurchasePage.vue');
const ContactPage = () => import (/* webpackChunkName: "ContactPage" */ '@/components/page/ContactPage.vue');
const PrivacyPolicyPage = () => import (/* webpackChunkName: "ContactPage" */ '@/components/page/PrivacyPolicy.vue');
 
export default new Router({
  // ↓URLの#を消す
  mode: "history",
  // ページ遷移したらスクロールを一番上に戻す
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeOriginPage
    },
    {
      path: '/company-profile',
      name: 'CompanyProfile',
      component: CompanyProfilePage
    },
    {
      path: '/business-content',
      name: 'BusinessContent',
      component: BusinessContentPage
    },
    {
      path: '/product-purchase',
      name: 'ProductPurchase',
      component: ProductPurchasePage
    },
    {
      path: '/contact',
      name: 'Contact',
      component: ContactPage
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicyPage
    },
  ]
})