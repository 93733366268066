<template>
    <header>
    <!-- ヘッダーメニュー -->
    <v-app-bar
        app
        light
        color=#2e8b57
        class="white--text"
    >
    <!-- ハンバーガーアイコンをクリックするとドロアーが開く -->
       <!-- スマホ画面の時のみハンガーアイコンを表示する
        ($vuetify.breakpoint.smはスマホ画面のとき) -->
        <!-- xs	600px未満
             sm	600px以上、960px未満
             md	960px以上、1264px未満
             lg	1264px以上、1904px未満
             xl	1904px以上 -->
        <v-app-bar-nav-icon color="white" v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" @click="changeDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title>株式会社池田工芸</v-toolbar-title>
        <!-- ヘッダーメニュー
        デスクトップ画面の時のみヘッダーメニューを表示する -->
        <v-tabs  v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          <!-- toはvue routerによるvuetifyが用意している画面遷移オプション -->
          <!-- なぜか勝手に$eventがついてしまう -->
            <v-tab class="white--text"
            v-for="(menuItem) in menuItems" :key="menuItem.name"
            @click="PageTransition(menuItem.url)">
            {{ menuItem.name }}
            </v-tab>
        </v-tabs>
    </v-app-bar>
    <!-- ハンガーアイコンのメニュー
    fixed:サイドメニューを左側いっぱいに表示する
    temporary:アプリケーションの一番上の層で表示が可能(下の画面はグレーアウトされる) -->
    <v-navigation-drawer
        v-model="drawer"
        fixed 
        temporary
    >
        <v-list
        nav
        dense
        >
        <v-list-item-group>
            <v-list-item v-for="(menuItem) in menuItems" :key="menuItem.name"
            @click="PageTransition(menuItem.url)">
            <v-list-item-content>{{menuItem.name}}</v-list-item-content>
            </v-list-item>
        </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
    </header>
  </template>
  
  <script>
  import constants from '@/common/consts'
  export default {
    name: 'AppHeader',
    components: {
    },
    data () {
      return {
        drawer: false,
        menuItems: constants.menuItems
  
      }
    },
    methods:{
      changeDrawer(){
        /* ドロアーの開閉 */
        this.drawer = !this.drawer
      },
      PageTransition(menuItemUrl){
        this.$router.push(menuItemUrl)
      }
    }
  };
  </script>
  
  <!-- langはどの言語を使用するか 今回はscssを使用 -->
  <style scoped>
  .v-toolbar__title {
    /* TODO: 画面が変わっても同じ位置にメニューが出るようにしたい */
    /* サイドメニューがタイトルを隠さないようにするための設定 */
    /* !importnatは最優先 */
    overflow: visible !important;
    margin-right: 200px !important;
  }
  /* .v-tabs{
    color: white
  } */
  /* #header{
    
  } */
  </style>